import _ from 'lodash'
window._ = _

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios'
window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

// Setup NProgress when using axios. This will use the progress settings of Inertia.
import NProgress from 'nprogress'
window.axios.interceptors.request.use(
  function (config) {
    NProgress.start()

    config.headers['Accept-Language'] = document.documentElement.lang ?? 'de'

    return config
  },
  function (error) {
    NProgress.done()
    console.error(error)
    return Promise.reject(error)
  }
)

window.axios.interceptors.response.use(
  function (response) {
    NProgress.done()
    return response
  },
  function (error) {
    NProgress.done()
    console.error(error)
    return Promise.reject(error)
  }
)

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
